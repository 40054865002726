import axios from 'axios'
import store from '../store'
import { ElMessage, ElMessageBox } from 'element-plus'
const http = axios.create({
  baseURL: '/api'
})
// 增加头部信息
http.interceptors.request.use(
  (config: any) => {
    // 检查是否有凭据
    const loginToken: any = store.getters['session/getSession']
    config.headers['Content-Type'] = 'application/json'
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // 获取凭据带给接口
    if (loginToken.token) {
      config.headers.token = encodeURIComponent(loginToken.token)
      config.headers['auth-key'] = encodeURIComponent(loginToken.authKey)
    }
    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)
http.interceptors.response.use(
  (response: any) => {
    const dataAxios = response.data
    const { code, status } = dataAxios
    if (code === 0 || status === 1) {
      return Promise.resolve(JSON.parse(response.config.data).params && JSON.parse(response.config.data).params.pageSize ? { data: dataAxios.data, page: dataAxios.page } : dataAxios.data)
    } else if (code === 1) {
      return Promise.resolve()
    } else {
      let msg = code === 10040102 ? dataAxios.data : dataAxios.msg
      switch (code) {
        case -101:
          msg = 'appkey或者secretKey不能为空'
          break
        case -111:
          msg = '入参必填参数为空'
          break
        case -112:
          msg = 'company不能为空'
          break
        case -113:
          msg = 'creditCode不能为空'
          break
        case -999:
          msg = '系统错误'
          break
        case -100:
          msg = '插入数据库失败请重试'
          break
      }
      // ElMessageBox.alert(msg)
      ElMessage({ type: 'error', message: msg })
      if (code === 10040102) {
        store.dispatch('company/set', {
          company: '',
          creditCode: ''
        })
        location.href = '/error'
      }
      return Promise.reject(dataAxios)
    }
  },
  (error: any) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = '请求地址出错'; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
    }
    ElMessage({ type: 'warning', message: error.message })
    return Promise.reject(error)
  }
)
export default http
