import http from '@/utils/http'
class Api {
  // 代理方法
  post = (url: string, data?: any) => {
    return http.post('/approvalv3/service/proxy', {
      route: url,
      method: 'post',
      params: data
    })
  }

  getJiBen = (data: any) => {
    return http.post('approvalv3/service/business-short', data)
  }

  /*
  * 黑名单
  */
  queryEnterpriseBlacklists = (data: any) => {
    return this.post('/businessInformation/queryEnterpriseBlacklists', data)
  }

  /*
  * 变更记录
  */
  getChange = (data: any) => {
    return this.post('/businessInformation/getChange', data)
  }

  /*
  * 企业工商信息查询
  */
  getBasicDetailsByName = (data: any) => {
    return this.post('/businessInformation/getBasicDetailsByName', data)
  }

  /*
  * 核验企业是否存在
  */
  getExistVerifyCompany = (data: any) => {
    return this.post('/businessInformation/getExistVerifyCompany', data)
  }

  /*
  * 企业工商模糊搜索
  */
  getFuzzySearch = (data: any) => {
    return this.post('/businessInformation/getFuzzySearch', data)
  }

  /*
  * 企业工商风险扫描
  */
  getInfoOverview = (data: any) => {
    return this.post('/businessInformation/getInfoOverview', data)
  }

  /*
  * 企业工商信息详情
  */
  getInfoVerify = (data: any) => {
    return this.post('/businessInformation/getInfoVerify', data)
  }

  /*
  * 股权变更信息
  */
  getSelfPublicStockChange = (data: any) => {
    return this.post('/businessInformation/getSelfPublicStockChange', data)
  }

  /*
  * 破产重整核查
  */
  getBankruptcyCheck = (data: any) => {
    return this.post('/legalProceedings/getBankruptcyCheck', data)
  }

  /*
  * 破产重整详情
  */
  getBankruptcyCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getBankruptcyCheckDetail', data)
  }

  /*
  * 立案信息核查
  */
  getCaseFilingCheck = (data: any) => {
    return this.post('/legalProceedings/getCaseFilingCheck', data)
  }

  /*
  * 立案信息详情
  */
  getCaseFilingCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getCaseFilingCheckDetail', data)
  }

  /*
  * 终本案件核查
  */
  getEndExecuteCaseCheck = (data: any) => {
    return this.post('/legalProceedings/getEndExecuteCaseCheck', data)
  }

  /*
  * 终本案件详情
  */
  getEndExecuteCaseCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getEndExecuteCaseCheckDetail', data)
  }

  /*
  * 股权冻结核查
  */
  getEquityFreezeCheck = (data: any) => {
    return this.post('/legalProceedings/getEquityFreezeCheck', data)
  }

  /*
  * 股权冻结详情
  */
  getEquityFreezeCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getEquityFreezeCheckDetail', data)
  }

  /*
  * 裁判文书核查
  */
  getJudgmentDocCheck = (data: any) => {
    return this.post('/legalProceedings/getJudgmentDocCheck', data)
  }

  /*
  * 裁判文书详情
  */
  getJudgmentDocCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getJudgmentDocCheckDetail', data)
  }

  /*
  * 失信核查
  */
  getShixinCheck = (data: any) => {
    return this.post('/legalProceedings/getShixinCheck', data)
  }

  /*
  * 失信详情
  */
  getShixinCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getShixinCheckDetail', data)
  }

  /*
  * 限制高消费核查
  */
  getSumptuaryCheck = (data: any) => {
    return this.post('/legalProceedings/getSumptuaryCheck', data)
  }

  /*
  * 限制高消费详情
  */
  getSumptuaryCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getSumptuaryCheckDetail', data)
  }

  /*
  * 被执行人核查
  */
  getZhixingCheck = (data: any) => {
    return this.post('/legalProceedings/getZhixingCheck', data)
  }

  /*
  * 被执行人详情
  */
  getZhixingCheckDetail = (data: any) => {
    return this.post('/legalProceedings/getZhixingCheckDetail', data)
  }

  /*
  * 企业新闻搜索
  */
  getCompanyNews = (data: any) => {
    return this.post('/enterpriseReport/getCompanyNews', data)
  }

  /*
  * 企业新闻详情
  */
  getCompanyNewsDetail = (data: any) => {
    return this.post('/enterpriseReport/getCompanyNewsDetail', data)
  }

  /*
  * 中标业绩
  */
  getQualificationComTenderCheck = (data: any) => {
    return this.post('/enterpriseReport/getQualificationComTenderCheck', data)
  }

  /*
  * 中标详情
  */
  getQualificationComTenderCheckDetail = (data: any) => {
    return this.post('/enterpriseReport/getQualificationComTenderCheckDetail', data)
  }

  /*
  * 企业自身风险扫描
  */
  getCompanySelfRiskCount = (data: any) => {
    return this.post('/enterpriseOperation/getCompanySelfRiskCount', data)
  }

  /*
  * 经营异常核查
  */
  getExceptionCheck = (data: any) => {
    return this.post('/enterpriseOperation/getExceptionCheck', data)
  }

  /*
  * 欠税公告核查
  */
  getTaxOweNoticeCheck = (data: any) => {
    return this.post('/enterpriseOperation/getTaxOweNoticeCheck', data)
  }

  /*
  * 欠税公告详情
  */
  getTaxOweNoticeCheckDetail = (data: any) => {
    return this.post('/enterpriseOperation/getTaxOweNoticeCheckDetail', data)
  }
}
export default new Api()
